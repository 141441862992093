<template>
  <b-card>
    <FormView
      :data="$store.getters['event/detailData']"
      :fields="fields"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'featuredImage',
          label: 'Cover',
          type: 'image',
        },
        {
          key: 'name',
          label: 'Title',
          type: 'profile',
          image: 'thumbImage',
        },
        {
          key: 'type',
          label: 'Type',
          type: 'dot',
          map: {
            sign_up: 'warning',
          },
        },
        {
          key: 'status',
          label: 'Status',
          type: 'badge',
          sortable: false,
          map: {
            published: 'success',
            waiting: 'warning',
            completed: 'success',
            canceled: 'danger',
          },
        },
        {
          key: 'cost',
          label: 'Cost',
          type: 'gold',
        },
        {
          key: 'sort',
          label: 'Sort',
        },
        {
          key: 'slug',
          label: 'Slug',
        },
        {
          key: 'startDate',
          label: 'Start Date',
          type: 'datetime',
        },
        {
          key: 'endDate',
          label: 'End Date',
          type: 'datetime',
        },
        //
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('event/getDetail', id)
    },
  },
}
</script>

  <style scoped>

  </style>
